<template>
  <div class="wrap">
    <el-empty v-if="list.length == 0" description="空空如也~"></el-empty>
    <ul v-else class="order-ul" v-infinite-scroll="getData">
      <li class="list-box" v-for="(m, index) in list">
        <div class="num-box">
          <p class="num flex">订单号：{{ m.number }}</p>
        </div>
        <div class="list">
          <div class="content">
            <h3 class="title">{{ m.title }}</h3>
            <div class="area flex" v-if="m.address">
              <i class="iconfont icon-zhiyuandidian6"></i>
              {{ m.address }}
            </div>
          </div>
          <p class="price">
            价格：
            <span>￥ {{ m.price }}{{ m.type == 1 ? '（' + m.num + '小时）' : '' }}</span>
          </p>
          <!-- // 订单状态 -4售后关闭|-3用户关闭|-2商家关闭|-1订单关闭|0正常 -->
          <div>
            <!-- state // 支付状态 -1已退款|0待付款|1已付款 -->
            <p class="price">
              服务状态：
              <span v-if="m.state == 0">{{ m.paystate != 1 ? payInfo[m.paystate] : sInfo[m.status] }}</span>
              <span v-else>订单已{{ m.state == -3 ? '取消' : '关闭' }}</span>
            </p>
            <!-- // 服务状态 -1未正常完成|0待服务|1商家设置已完成(等待顾客确认)|2服务完成 -->
            <div class="flex-bt">
              <div class="user flex" v-if="m.shopowner">
                <img class="tx" :src="rImg(m.shopowner.head_portrait)" :onerror="txImg" alt="" />
                <p class="name">{{ m.shopowner.name }}</p>
              </div>
              <div v-if="m.state == 0">
                <el-button v-if="m.paystate == -1 || m.paystate == -1 || m.paystate == 2" round class="btn" @click.stop="delOrder(index, m.id)">删除订单</el-button>
                <div class="btn-box" v-if="!m.paystate">
                  <el-button round class="btn" v-if="!m.status" @click.stop="cancelOrder(index, m.id)">取消</el-button>
                  <el-button round class="btn" @click.stop="paySkillOrder(index, m.id)">立即付款</el-button>
                </div>
                <div class="btn-box" v-else-if="m.paystate == 1">
                  <el-button round class="btn" v-if="m.status == 1" @click.stop="submitOrder(index, m.id, 2)">确认</el-button>
                  <el-button round class="btn" v-if="m.status == 2" @click.stop="delOrder(index, m.id)">删除订单</el-button>
                </div>
              </div>
              <el-button v-else round class="btn" @click.stop="delOrder(index, m.id)">删除订单</el-button>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {},
  data() {
    return {
      list: [],
      payInfo: {
        "-1": "已退款",
        0: "待付款",
        1: "已付款",
      },
      sInfo: {
        "-1": "售后中",
        0: "进行中",
        1: "待确认",
        2: "已结束",
      },
    };
  },
  created() {
    // 在组件实例处理完所有与状态相关的选项后调用。
    this.getData(1);
  },
  mounted() {
    // 在组件被挂载之后调用。
  },
  methods: {
    // ...mapMutations([])
    async getData(first) {
      let param = {
        type: 1,
      };
      if (first) {
        param.page = 1;
      }
      this.list = await this.$y_list("api/service/Orders", this.list, param);
    },
    // 删除订单
    async delOrder(index, id) {
      let ret = await this.$y_ajax("api/service/OrderDele", {
        order_id: id,
      });
      this.$y_msg(ret.msg);
      if (ret && ret.code == 200) {
        this.list.splice(index, 1);
      }
    },
    // 确认订单
    async submitOrder(index, id, status) {
      let ret = await this.$y_ajax("api/service/OrderStatus", {
        order_id: id,
      });
      this.$y_msg(ret.msg);
      if (ret && ret.code == 200) {
        this.list[index].status = status;
      }
    },
    // 取消订单
    async cancelOrder(index, id) {
      let ret = await this.$y_ajax("api/service/OrderCancel", {
        order_id: id,
      });
      _msg(ret.msg);
      if (ret && ret.code == 200) {
        this.list[index].state = -3;
      }
    },
    // 付款
    async paySkillOrder(index, id) {},
  },
  computed: {
    // 计算属性
    // ...mapGetters([])
  },
  watch: {
    // 监听
  },
};
</script>

<style lang="less" scoped>
@import url("../../assets/less/order.less");
</style>
